<!-- eslint-disable -->
<template>
  <div id="app">
    <div class="reset" @click="reset">RESET</div>
    <h2>EVIDENCE</h2>
    <div class="evidence">
      <h3>NOT FOUND</h3>
      <div @click="addEvidence(item)" v-for="(item, index) in evidenceNotFound" :key="index" class="item">
        {{ item.name }}
      </div>
    </div>
    <h3>FOUND</h3>
    <div class="evidence">
      <div v-if="evidenceFound.length > 0">
        <div @click="removeEvidence(item)" v-for="(item, index) in evidenceFound" :key="index" class="item">
          {{ lookupEvidence(item).name }}
        </div>
      </div>
      <div v-else>
        None
      </div>
    </div>

    <br><br>
    <div class="ghosts">
      <h3>POSSIBLE GHOSTS</h3>
      <div v-if="possibleGhosts.length > 0">
        <div v-for="(ghost, index) in possibleGhosts" :key="index" class="ghost">
          <div class="name">{{ ghost.name }}</div>
          <div class="evidence">
            <span v-for="(evd, ind) in ghost.evidence" :key="ind" class="evd" :class="{ found: evidenceFound.indexOf(evd) > -1}">{{ lookupEvidence(evd).name }}</span>
          </div>
          <div class="info-name">STRENGTH</div>
          <div class="info">{{ghost.strength}}</div>
          <div class="info-name">WEAKNESS</div>
          <div class="info">{{ghost.weakness}}</div>
        </div>
      </div>
      <div v-else>
        None, idiot.
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ghosts, evidence } from './data.js';

export default {
  data() {
    return {
      evidenceFound: [],
    }
  },
  computed: {
    ghosts() {
      return ghosts
    },
    evidence() {
      return evidence
    },
    evidenceNotFound() {
      return Object.values(evidence).filter((item) => {
        return this.evidenceFound.indexOf(item.value) === -1;
      });
    },
    possibleGhosts() {
      return ghosts.filter((ghost) => {
        let possible = [];
        this.evidenceFound.forEach((evd) => {
          if (ghost.evidence.indexOf(evd) > -1) {
            possible.push(true);
          }
        })
        return (possible.length === this.evidenceFound.length)
      });
    },
    ruledOutGhosts() {
      return ghosts.filter((ghost) => {
        let possible = [];
        this.evidenceFound.forEach((evd) => {
          if (ghost.evidence.indexOf(evd) > -1) {
            possible.push(true);
          }
        })
        return (possible.length !== this.evidenceFound.length)
      });
    }
  },
  methods: {
    reset() {
      this.evidenceFound = [];
    },
    lookupEvidence(evidenceValue) {
      return Object.values(evidence).find((e) => e.value === evidenceValue);
    },
    addEvidence(item) {
      if (this.evidenceFound.length < 3) {
        this.evidenceFound.push(item.value);
      }
    },
    removeEvidence(item) {
      const index = this.evidenceFound.indexOf(item);
      if (index > -1) this.evidenceFound.splice(index, 1);
    }
  }
}
</script>

<style lang="scss">

body {
  background: rgba(0, 0, 0, .95);
}

#app {
  position: relative;
  display: block;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;

  .reset {
    position: relative;
    display: inline-block;
    background: red;
    color: black;
    padding: 16px 24px;
    border: black 1px solid;
    font-weight: bold;
    cursor: pointer;
  }

  .evidence {
    .item {
      cursor: pointer;
      display: inline-block;
      position: relative;
      padding: 16px;
      margin: 8px;
      background: rgba(100, 0, 0, .24);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24);
      font-weight: bold;
      width: 140px;
      transition: all .2s ease;

      &:hover {
        background: rgba(100, 0, 0, .5);
      }
    }
  }

  .ghosts {
    .ghost {
      position: relative;
      display: inline-block;
      margin: 16px;
      padding: 16px;
      border: 1px solid rgba(0, 0, 0, .12);
      background: rgba(100, 0, 0, .24);
      max-width: 360px;
      width: 100%;
      height: 240px;
      vertical-align: top;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .24);

      .name {
        font-size: 20px;
        margin: 8px;
        text-transform: uppercase;
        font-weight: bold;
      }

      .evidence {
        margin: 0 0 20px;
        .evd {
          margin: 8px;
          color: red;
          font-weight: bold;

          &.found {
            color: white;
            opacity: .5;
            font-weight: normal;
          }
        }
      }

      .info-name {
        font-weight: bold;
        margin: 16px 0;
      }
      
      .info {
        margin: 0 0 8px;
      }
    }
  }
}
</style>
