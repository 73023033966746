/* eslint-disable */

export const evidence = {
  emf: {
    name: 'EMF Level 5',
    value: 'emf',
  },
  fingerprints: {
    name: 'Fingerprints',
    value: 'fingerprints',
  },
  freezingTemps: {
    name: 'Freezing Temps',
    value: 'freezingTemps',
  },
  ghostOrb: {
    name: 'Ghost Orb',
    value: 'ghostOrb',
  },
  ghostWriting: {
    name: 'Ghost Writing',
    value: 'ghostWriting',
  },
  spiritbox: {
    name: 'Spirit Box',
    value: 'spiritBox',
  },
};

export const ghosts = [
  {
    name: 'Banshee',
    evidence: [evidence.emf.value, evidence.fingerprints.value, evidence.freezingTemps.value],
    strength: `Only targets one player at a time, giving them a really bad night.`,
    weakness: `Hates the Crucifix, making it especially effective.`
  },
  {
    name: 'Demon',
    evidence: [evidence.freezingTemps.value, evidence.ghostWriting.value, evidence.spiritbox.value],
    strength: `Once of the most dangerous ghosts. Extremely aggressive with attacks.`,
    weakness: `Using the Ouija board to ask questions doesn’t drain sanity.`,
  },
  {
    name: 'Jinn',
    evidence: [evidence.spiritbox.value, evidence.ghostOrb.value, evidence.emf.value],
    strength: `The Jinn moves faster the further away you are from it.`,
    weakness: `Cutting off the location’s power supply will limit the Jinn’s speed.`,
  },
  {
    name: 'Mare',
    evidence: [evidence.spiritbox.value, evidence.ghostOrb.value, evidence.freezingTemps.value],
    strength: `Attacks more frequently in the dark, tends to switch off lights.`,
    weakness: `Keeping the lights on.`,
  },
  {
    name: 'Oni',
    evidence: [evidence.emf.value, evidence.ghostWriting.value, evidence.spiritbox.value],
    strength: `Extremely active and moves objects quickly. `,
    weakness: `Extreme activity with lots of players nearby makes it easy to identify.`,
  },
  {
    name: 'Phantom',
    evidence: [evidence.emf.value, evidence.ghostOrb.value, evidence.freezingTemps.value],
    strength: `Looking at the Phantom will reduce your sanity.`,
    weakness: `Scared of photos, capturing a photo will cause it to disappear, though not during a hunt.`,
  },
  {
    name: 'Poltergeist',
    evidence: [evidence.spiritbox.value, evidence.fingerprints.value, evidence.ghostOrb.value],
    strength: `Throws multiple objects about at once.`,
    weakness: `Rooms without any stuff in to throw.`,
  },
  {
    name: 'Revenant',
    evidence: [evidence.emf.value, evidence.fingerprints.value, evidence.ghostWriting.value],
    strength: `Attacks anyone, regardless of sanity levels.`,
    weakness: `Moves extremely slowly when players hide.`,
  },
  {
    name: 'Shade',
    evidence: [evidence.emf.value, evidence.ghostOrb.value, evidence.ghostWriting.value],
    strength: `Extremely shy, making it hard to find and detect.`,
    weakness: `Won’t hunt if players are in a group.`,
  },
  {
    name: 'Spirit',
    evidence: [evidence.spiritbox.value, evidence.fingerprints.value, evidence.ghostWriting.value],
    strength: `A basic ghost with no strengths.`,
    weakness: `Using smudge sticks stops from starting for a long time.`,
  },
  {
    name: 'Wraith',
    evidence: [evidence.fingerprints.value, evidence.freezingTemps.value, evidence.spiritbox.value],
    strength: `One of the most dangerous ghosts. Can fly through walls and doesn’t leave footprints.`,
    weakness: `Has a strong reaction to salt.`,
  },
  {
    name: 'Yurei',
    evidence: [evidence.freezingTemps.value, evidence.ghostOrb.value, evidence.ghostWriting.value],
    strength: `Drains player sanity especially quickly.`,
    weakness: `Using a smudge stick in the same room will stop the Yurei from moving.`,
  },
]